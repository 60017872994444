import PropTypes from "prop-types";
import React from "react";

import { getUrlSanitizer } from "@elastic/react-search-ui-views/es/view-helpers";
import { isFieldValueWrapper } from "@elastic/react-search-ui-views/es/types/FieldValueWrapper";
import { Accordion, Anchor, Avatar, Badge, Box, Card, Group, Text, Title } from "@mantine/core";
import { FileText, Star } from "tabler-icons-react";

function getFieldType(result, field, type) {
  if (result[field]) return result[field][type];
}

function getScore(result) {
  const score = result && result._meta ? result._meta.score || 0 : 0;
  return parseFloat(score).toFixed(2);
}

function getRaw(result, field) {
  return getFieldType(result, field, "raw");
}

function getSnippet(result, field) {
  return getFieldType(result, field, "snippet");
}

function htmlEscape(str) {
  if (!str) return "";

  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function getResultText(result) {
  if (result["body_content"]) {
    const safeField =
    getSnippet(result, "body_content") || htmlEscape(getRaw(result, "body_content"));
    return Array.isArray(safeField) ? safeField.join(", ") : safeField;
  }
  return "";
}

function getEscapedField(result, field) {
  // Fallback to raw values here, because non-string fields
  // will not have a snippet fallback. Raw values MUST be html escaped.
  if (field == "body_content") {
    const safeContent = htmlEscape(getRaw(result, field));
    return Array.isArray(safeContent) ? safeContent.join(", ") : safeContent;
  }
  const safeField =
    getSnippet(result, field) || htmlEscape(getRaw(result, field));
  return Array.isArray(safeField) ? safeField.join(", ") : safeField;
}

function getEscapedFields(result) {
  return Object.keys(result).reduce((acc, field) => {
    // If we receive an arbitrary value from the response, we may not properly
    // handle it, so we should filter out arbitrary values here.
    //
    // I.e.,
    // Arbitrary value: "_metaField: '1939191'"
    // vs.
    // FieldValueWrapper: "_metaField: {raw: '1939191'}"
    if (!isFieldValueWrapper(result[field])) return acc;
    return { ...acc, [field]: getEscapedField(result, field) };
  }, {});
}

function Result({
  className,
  result,
  onClickLink,
  titleField,
  urlField,
  thumbnailField,
  ...rest
}) {
  const fields = getEscapedFields(result);
  const title = getEscapedField(result, titleField);
  const url = getUrlSanitizer(URL, window.location)(getRaw(result, urlField));
  const thumbnail = getUrlSanitizer(URL, window.location)(
    getRaw(result, thumbnailField)
  );

  if ( fields.url_path_dir3 === "news" || fields.url_path_dir2 === "news" || fields.url_path_dir2 === "uutiset" ) {
    return (
      <Card
        shadow="sm"
        sx={{
          marginBottom: 24,
          backgroundColor: "#eaf6ff"
        }}
        { ...rest }
      >
        <Group direction="column">
          {title && url && (
            <Anchor
              color="blue"
              href={ url }
              onClick={ onClickLink }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Title order={ 2 } dangerouslySetInnerHTML={{ __html: title }}/>
            </Anchor>
          )}
            <Text dangerouslySetInnerHTML={{__html: getResultText(result)}}></Text>
          <Group position="apart">
            <Badge variant="dot">Uutiset</Badge>
            <Badge>{`Score ${getScore(result)}`}</Badge>
          </Group>
        </Group>
        <Accordion sx={{ width: "100%" }}>
          <Accordion.Item label="Näytä data">
            <ul className="sui-result__details">
              {Object.entries(fields).map(([fieldName, fieldValue]) => (
                <li key={fieldName}>
                  <span className="sui-result__key">{fieldName}</span>{" "}
                  <span
                    className="sui-result__value"
                    dangerouslySetInnerHTML={{ __html: fieldValue }}
                  />
                </li>
              ))}
            </ul>
          </Accordion.Item>
        </Accordion>
      </Card>
    )
  }

  if ( (fields.url_path || "").endsWith(".pdf") ) {
    return (
      <Card
        shadow="sm"
        sx={{
          marginBottom: 24,
          backgroundColor: "#fff"
        }}
        { ...rest }
      >
        <Group direction="row" spacing="sm">
          <Avatar>
            <FileText/>
          </Avatar>
          <Box>
            <Title order={ 3 }>{ fields.title }</Title>
            <Text dangerouslySetInnerHTML={{__html: getResultText(result)}}></Text>
          </Box>
        </Group>
        <Group direction="column">
          <Anchor
            target="_blank"
            href={ !fields.url.endsWith(".pdf") ? fields.url.concat(".pdf") : fields.url }
          >
            Avaa tiedosto
          </Anchor>
          <Group position="apart">
            <Badge>PDF</Badge>
            <Badge>{`Score ${getScore(result)}`}</Badge>
          </Group>
        </Group>
        <Accordion sx={{ width: "100%" }}>
          <Accordion.Item label="Näytä data">
            <ul className="sui-result__details">
              {Object.entries(fields).map(([fieldName, fieldValue]) => (
                <li key={fieldName}>
                  <span className="sui-result__key">{fieldName}</span>{" "}
                  <span
                    className="sui-result__value"
                    dangerouslySetInnerHTML={{ __html: fieldValue }}
                  />
                </li>
              ))}
            </ul>
          </Accordion.Item>
        </Accordion>
      </Card>
    )
  }

  if ( (fields.url_path || "").endsWith(".pptx") ) {
    return (
      <Card
        shadow="sm"
        sx={{
          marginBottom: 24,
          backgroundColor: "#fff"
        }}
        { ...rest }
      >
        <Group direction="row" spacing="sm">
          <Avatar>
            <FileText/>
          </Avatar>
          <Box>
            <Title order={ 3 }>{ fields.title }</Title>
            <Text dangerouslySetInnerHTML={{__html: getResultText(result)}}></Text>
          </Box>
        </Group>
        <Group direction="column">
          <Anchor
            target="_blank"
            href={ !fields.url.endsWith(".pptx") ? fields.url.concat(".pptx") : fields.url }
          >
            Avaa tiedosto
          </Anchor>
          <Group position="apart">
            <Badge>Powerpoint</Badge>
            <Badge>{`Score ${getScore(result)}`}</Badge>
          </Group>
        </Group>
        <Accordion sx={{ width: "100%" }}>
          <Accordion.Item label="Näytä data">
            <ul className="sui-result__details">
              {Object.entries(fields).map(([fieldName, fieldValue]) => (
                <li key={fieldName}>
                  <span className="sui-result__key">{fieldName}</span>{" "}
                  <span
                    className="sui-result__value"
                    dangerouslySetInnerHTML={{ __html: fieldValue }}
                  />
                </li>
              ))}
            </ul>
          </Accordion.Item>
        </Accordion>
      </Card>
    )
  }

  if ( (fields.url_path || "").endsWith(".docx") ) {
    return (
      <Card
        shadow="sm"
        sx={{
          marginBottom: 24,
          backgroundColor: "#fff"
        }}
        { ...rest }
      >
        <Group direction="row" spacing="sm">
          <Avatar>
            <FileText/>
          </Avatar>
          <Box>
            <Title order={ 3 }>{ fields.title }</Title>
            <Text dangerouslySetInnerHTML={{__html: getResultText(result)}}></Text>
          </Box>
        </Group>
        <Group direction="column">
          <Anchor
            target="_blank"
            href={ !fields.url.endsWith(".docx") ? fields.url.concat(".docx") : fields.url }
          >
            Avaa tiedosto
          </Anchor>
          <Group position="apart">
            <Badge>WORD</Badge>
            <Badge>{`Score ${getScore(result)}`}</Badge>
          </Group>
        </Group>
        <Accordion sx={{ width: "100%" }}>
          <Accordion.Item label="Näytä data">
            <ul className="sui-result__details">
              {Object.entries(fields).map(([fieldName, fieldValue]) => (
                <li key={fieldName}>
                  <span className="sui-result__key">{fieldName}</span>{" "}
                  <span
                    className="sui-result__value"
                    dangerouslySetInnerHTML={{ __html: fieldValue }}
                  />
                </li>
              ))}
            </ul>
          </Accordion.Item>
        </Accordion>
      </Card>
    )
  }

  if ( (fields.url_path_dir2 || "").endsWith("education") || (fields.url_path_dir2 || "").endsWith("schools") || (fields.url_path_dir3 || "").endsWith("opetus") || (fields.url_path_dir2 || "").endsWith("opetus") || (fields.url_path_dir3 || "").endsWith("school") ) {
    return (
      <Card
        shadow="sm"
        sx={{
          marginBottom: 24,
          backgroundColor: "#fff0c4"
        }}
        { ...rest }
      >
        <Group direction="column">
          {title && url && (
            <Anchor
              color="dark"
              href={ url }
              onClick={ onClickLink }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Title order={ 2 } dangerouslySetInnerHTML={{ __html: title }}/>
            </Anchor>
          )}
          <Text dangerouslySetInnerHTML={{__html: getResultText(result)}}></Text>
          <Group position="apart">
            <Badge color="orange" variant="dot">Opiskelu / koulutus</Badge>
            <Badge>{`Score ${getScore(result)}`}</Badge>
          </Group>
        </Group>
        <Accordion sx={{ width: "100%" }}>
          <Accordion.Item label="Näytä data">
            <ul className="sui-result__details">
              {Object.entries(fields).map(([fieldName, fieldValue]) => (
                <li key={fieldName}>
                  <span className="sui-result__key">{fieldName}</span>{" "}
                  <span
                    className="sui-result__value"
                    dangerouslySetInnerHTML={{ __html: fieldValue }}
                  />
                </li>
              ))}
            </ul>
          </Accordion.Item>
        </Accordion>
      </Card>
    )
  }

  return (
    <Card shadow="sm" sx={{ marginBottom: 24, backgroundColor: "#f7f7f7" }} {...rest}>
      <Group direction="column">
        {title && !url && (
          <Text
            className="sui-result__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {title && url && (
          <Anchor
            size="xl"
            dangerouslySetInnerHTML={{ __html: title }}
            href={ url }
            onClick={ onClickLink }
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
        <Text dangerouslySetInnerHTML={{__html: getResultText(result)}}></Text>
        <Group position="apart">
          <Badge color="gray" variant="dot">Muu</Badge>
          <Badge>{`Score ${getScore(result)}`}</Badge>
        </Group>
      </Group>
      <div>
        {thumbnail && (
          <div className="sui-result__image">
            <img src={thumbnail} alt="" />
          </div>
        )}
      </div>
      <Accordion sx={{ width: "100%" }}>
        <Accordion.Item label="Näytä data">
          <ul className="sui-result__details">
            {Object.entries(fields).map(([fieldName, fieldValue]) => (
              <li key={fieldName}>
                <span className="sui-result__key">{fieldName}</span>{" "}
                <span
                  className="sui-result__value"
                  dangerouslySetInnerHTML={{ __html: fieldValue }}
                />
              </li>
            ))}
          </ul>
        </Accordion.Item>
      </Accordion>
    </Card>
  );
}

Result.propTypes = {
  result: PropTypes.object.isRequired,
  onClickLink: PropTypes.func.isRequired,
  className: PropTypes.string,
  titleField: PropTypes.string,
  urlField: PropTypes.string,
  thumbnailField: PropTypes.string
};

export default Result;